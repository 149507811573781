import React from "react"
import { graphql } from "gatsby"

import Typography from "@material-ui/core/Typography"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../components/layout/layout"

import YellowHeader from "../components/general/yellowHeader"

import useStyles from "./textPages.style"
import SEO from "../components/seo"

export const query = graphql`
  query TermsQuery($language: String!) {
    contentfulCgus(node_locale: { eq: $language }) {
      title
      seo {
        title
        description
      }
      content {
        json
      }
    }
  }
`

export default function Terms({ data }) {
  const classes = useStyles()

  return (
    <Layout>
      <SEO
        title={data.contentfulCgus.title}
        description={data.contentfulCgus.description}
      />
      <YellowHeader title={data.contentfulCgus.title} />

      <div className={classes.wrapper}>
        {documentToReactComponents(data.contentfulCgus.content.json, {
          renderNode: {
            [BLOCKS.HEADING_2]: (_node, children) => (
              <Typography
                className={classes.sectionTitle}
                variant="h5"
                color="initial"
              >
                {children}
              </Typography>
            ),
            [BLOCKS.PARAGRAPH]: (_node, children) => (
              <Typography
                className={classes.paragraph}
                variant="button"
                color="initial"
              >
                {children}
              </Typography>
            ),
            [BLOCKS.EMBEDDED_ASSET]: node => (
              <img
                src={`${node.data.target.fields.file["en-US"].url}?w=300&q=90`}
                alt={node.data.target.fields.title["en-US"]}
              />
            ),
          },
        })}
      </div>
    </Layout>
  )
}
